'use client'
import React from 'react'
import createCache from '@emotion/cache'
import { useServerInsertedHTML } from 'next/navigation'
import { CacheProvider } from '@emotion/react'
import { CssVarsProvider, extendTheme } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import exportedTheme from './theme.json'

declare module '@mui/joy/Table' {
  interface TablePropsBorderAxisOverrides {
    header: true
  }
}

declare module '@mui/joy/Sheet' {
  interface SheetPropsVariantOverrides {
    glowing: true
  }
}

const theme = extendTheme({
  colorSchemes: exportedTheme.colorSchemes,
  fontFamily: {
    display: 'Reddit Sans Variable',
    body: 'Reddit Sans Variable',
  },
  typography: {
    'h1': {
      fontWeight: 'var(--joy-fontWeight-xl, 800)',
    },
    'h2': {
      fontWeight: 'var(--joy-fontWeight-xl, 800)',
    },
    'h3': {
      fontWeight: 'var(--joy-fontWeight-xl, 800)',
    },
    'h4': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '27.9px',
    },
    'title-lg': {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '29.88px',
    },
    'title-md': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    'title-sm': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
    },
    'body-sm': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '132%',
    },
    'body-xs': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '124%',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280,
    },
  },
  components: {
    JoyTable: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          'tbody tr:nth-of-type(odd)': {
            background: 'var(--joy-palette-neutral-100)',
          },
          'thead th': {
            fontWeight: '400',
            verticalAlign: 'middle',
            whiteSpace: 'normal',
          },
          'tfoot tr td': {
            background: 'var(--joy-palette-primary-100)',
          },
          'tfoot tr th': {
            background: 'var(--joy-palette-primary-100)',
          },
          ...(ownerState.borderAxis === 'header' && {
            '& thead th': {
              borderBottom: '1px solid var(--TableCell-borderColor)',
            },
          }),
        }),
      },
    },
    JoySlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: 'var(--joy-palette-primary-600)',
        },
        rail: {
          backgroundColor: 'var(--joy-palette-primary-100)',
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: {
          '&.MuiOption-highlighted': { background: 'var(--joy-palette-primary-100)' },
        },
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.radius.xs,
          boxShadow: theme.shadow.xs,
          overflow: 'hidden',
          ...(ownerState.variant === 'outlined' && {
            borderColor: 'var(--joy-palette-neutral-200)',
          }),
          ...(ownerState.variant === 'glowing' && {
            'color': theme.vars.palette.text.primary,
            'background': theme.vars.palette.background.surface,
            'border': '1px solid var(--joy-palette-primary-400)',
            'boxShadow': theme.shadow.lg,
            '--joy-shadowChannel': theme.vars.palette.primary.mainChannel,
            'borderRadius': '2px',
          }),
        }),
      },
    },
    JoyLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.underline === 'none' && {
            fontWeight: 700,
            fontSize: '14px',
            color: 'var(--joy-palette-primary-600)',
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // remove the stuck hover effect from buttons on mobile
          ...(ownerState.variant === 'plain' && ownerState.color === 'primary' && {
            [theme.breakpoints.only('xs')]: {
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0)',
              },
              '&:active': {
                backgroundColor: 'rgba(0,0,0,0)',
              },
              '&:focus': {
                backgroundColor: 'rgba(0,0,0,0)',
              },
            },
          }),
        }),
      },
    },
  },
})

export default function ThemeRegistry({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [{ cache, flush }] = React.useState(() => {
    const cache = createCache({ key: 'joy' })
    cache.compat = true
    const prevInsert = cache.insert
    let inserted: string[] = []
    cache.insert = (...args) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache,
      flush }
  })

  useServerInsertedHTML(() => {
    const names = flush()
    if (names.length === 0) {
      return null
    }
    let styles = ''
    for (const name of names) {
      styles += cache.inserted[name]
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    )
  })

  return (
    <CacheProvider value={cache}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </CacheProvider>
  )
}
