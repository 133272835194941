'use client'
import React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Box, Dropdown, IconButton, Link, ListItemDecorator, Menu, MenuButton, MenuItem, Sheet, Typography } from '@mui/joy'
import { ArrowBack, Build, Calculate, EmojiPeopleRounded, Home, Logout, MenuSharp } from '@mui/icons-material'
import { signOut, useSession } from 'next-auth/react'
import useLocalStorage from '../useLocalStorage'
import { useParams, usePathname, useRouter } from 'next/navigation'

const CustomPaths = ['/installers/:id', '/installers/:id/gallery'] as const

type CustomPath = typeof CustomPaths[number]

function isPath(segment: string): segment is CustomPath {
  return (CustomPaths as unknown as string[]).includes(segment)
}

type SiteNav = {
  title: string
}

const TITLES: { [_ in CustomPath]: string } = {
  '/installers/:id': '',
  '/installers/:id/gallery': 'Gallery',
}

function useSiteNav(): SiteNav | null {
  const { id } = useParams<{ id: string }>()
  const pathname = usePathname()
  const siteNav: SiteNav | null = React.useMemo<SiteNav | null>(() => {
    const segment = pathname.replace(id, ':id')
    if (isPath(segment)) {
      return { title: TITLES[segment] }
    } else {
      return null
    }
  }, [id, pathname])

  return siteNav
}

export default function TopNavigation() {
  const router = useRouter()
  const siteNav = useSiteNav()
  const { data: session } = useSession()
  const { value: searchId } = useLocalStorage('search-id')

  return (
    <Sheet
      variant="soft"
      sx={{
        boxShadow: 'xs',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: { xs: '48px', md: '60px' },
        paddingX: { xs: 2 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 0 0',
          maxWidth: 'lg',
        }}
      >
        {siteNav == null
          ? (
            <Link component={NextLink} href="/" sx={{ textDecoration: 'none' }}>
              <Image
                src="/logo.svg"
                alt="Orang Energy logo with wordmark"
                height={24}
                width={190}
                priority
              />
            </Link>
            )
          : (
            <>
              <IconButton onClick={() => router.back()}>
                <ArrowBack />
              </IconButton>
              <Typography level="title-md">{siteNav.title}</Typography>
            </>
            )}
        <Dropdown>
          <MenuButton
            sx={{ justifyContent: 'center', alignItems: 'center' }}
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: 'plain' } }}
          >
            <MenuSharp />
          </MenuButton>
          <Menu placement="bottom-end" size="md" variant="soft">
            <MenuItem>
              <ListItemDecorator>
                <Home />
              </ListItemDecorator>
              <Link component={NextLink} href="/" underline="none" overlay>Homepage</Link>
            </MenuItem>
            <MenuItem>
              <ListItemDecorator>
                <Calculate />
              </ListItemDecorator>
              <Link component={NextLink} href="/calculator" underline="none" overlay>Energy savings calclator</Link>
            </MenuItem>
            <MenuItem>
              <ListItemDecorator>
                <Build />
              </ListItemDecorator>
              <Link component={NextLink} href={`/installers${searchId != null ? `?searchId=${searchId}` : ''}`} underline="none" overlay>
                Installers near you
              </Link>
            </MenuItem>
            <MenuItem>
              <ListItemDecorator>
                <EmojiPeopleRounded />
              </ListItemDecorator>
              <Link component={NextLink} href="https://www.turbine.education/orang" underline="none" overlay target="_blank">About us</Link>
            </MenuItem>
            {session && (
              <MenuItem onClick={() => signOut({ callbackUrl: '/' })}>
                <ListItemDecorator>
                  <Logout />
                </ListItemDecorator>
                <Typography>Log out</Typography>
              </MenuItem>
            )}
          </Menu>
        </Dropdown>
      </Box>
    </Sheet>
  )
}
