'use client'

import { Session } from 'next-auth'
import {
  SessionProvider as NextAuthSessionProvider,
  useSession,
} from 'next-auth/react'
import { notFound, usePathname, useRouter, useSearchParams } from 'next/navigation'
import React from 'react'
import { LOGIN_PATH } from '../auth'

// We need to wrap the upstream component to tell nextjs it's a client component
export function SessionProvider({
  children,
  ...props
}: {
  children: React.ReactNode
} & Parameters<typeof NextAuthSessionProvider>[0]): React.ReactNode {
  return (
    <NextAuthSessionProvider {...props}>{children}</NextAuthSessionProvider>
  )
}

// Client-side component to require authentication.
export function Authenticated({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { data: session } = useSession()

  React.useEffect(() => {
    if (!session) {
      const callbackUrl = `${pathname}?${searchParams}`
      router.push(`${LOGIN_PATH}?${new URLSearchParams({ callbackUrl })}`)
    }
  }, [session, pathname, searchParams, router])

  return session ? children : null
}

// Client-side component to require authenticated administrator.
export function ForAdministratorsOnly({
  showNotFound = true,
  children,
}: {
  showNotFound?: boolean
  children: React.ReactNode
}): React.ReactNode {
  const { user } = useAuthenticated()

  if (!user.isAdmin) {
    return showNotFound ? notFound() : null
  }

  return children
}

// Client-side hook to obtain the current session or throw (should be used inside Authenticated).
export function useAuthenticated(): Session {
  const { data: session } = useSession()

  if (!session) {
    throw new Error(`useAuthenticated called when unauthenticated`)
  }

  return session
}
